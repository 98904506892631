//Small print slip
export const activeSmallInvoice = [
  {
    dc_id: 6,
    is_print: {
      is_cold: 1,
      is_fnv: 1,
    },
  },
  {
    dc_id: 8,
    is_print: {
      is_cold: 0,
      is_fnv: 1,
    },
  },
  {
    dc_id: 13,
    is_print: {
      is_cold: 1,
      is_fnv: 1,
    },
  },
];

// xSmall bag series
export const xSmallBagSeries = ["XI", "XJ", "XO", "XP", "XF", "XS", "XE"];

// small invoice tables
// Table 12, 13, 14, 15, 16  - Manesar dc
// Table 1, 2, 3, 4  5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15 - Noida dc
// Table 22_cold, 23_cold, 40_cold, 41_cold  - Noida dc
// Table 8 - Thane dc
export const smallInvoiceTableEnabled = [
  989, 998, 1107, 1114, 1137, 1138, 1140, 1360, 1362, 1363, 1421, 1445, 1464,
  1368, 1369, 1423, 1389, 1425, 1367, 1390, 1391, 1392, 1393, 1424, 1415, 1489,
  1490,
];

// explicitly disable small invoice
// Manesar dc = Table_2, Table_3
// export const smallInvoiceTableDisabled = [989, 998]
export const smallInvoiceTableDisabled = [];

// tableStatus filter
export const tableStatusFilter = [
  { id: 0, name: "15 mins" },
  { id: 1, name: "Hourly" },
];

// making switcher conditional
// please enter numbers in string
export const allowedSwitcherPhone = [
  // manesar dc = table_10, table_9
  "9658741230",
  "1475966265",
  // nodia dc = table_7, table_9
  "8860730702",
  "8076222578",
];

// please enter dc in integer
export const allowedDc = [];
// months name
export const MonthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

// single scan slip dc

export const singleScanDc = [6];

// single scan pickslip route ids
// route 500 --> 7158
// route 98  --> 1303
// route 602 --> 1356

// route 651 --> 7731
// route 131 --> 6096
// route 310 --> 7668

// route 222 --> 10830
// route 99  --> 1381
export const singleScanRoutes = [
  7158, 1356, 7731, 7668, 1303, 6096, 10830, 1381,
];

// long tail routes
// [DEL] route 99 --> 1381
// [NOI] route 18 --> 7462
// [NOI] route 555 --> 4936
// [GGN] Route 306 --> 1580
// [GGN] Route 506 --> 1413
export const longTailRoutes = [1381, 7462, 4936, 9419, 6662, 1580, 1413];

export const chamberDcEnabled = ["6", "8"];
