// api urls and static data urls will be here
import { get_access_token } from "./auth";
export const access_token = get_access_token();
export const configApi = "appconfig/otipybzapp/";
// export const Base = "https://api.crofarm.com/";
// export const Product = "https://api.crofarm.com/spa/";
// export const GcpBase = "https://api.crofarm.com/";

// export const Base = 'https://dev2.crofarm.com/';
// export const Product = 'https://dev2.crofarm.com/spa/';

// export const Base = 'http://stageapi.crofarm.com/v2/';
// export const Product = 'http://stageapi.crofarm.com/v2/spa/';

export const Base = "https://stagemain.crofarm.com/";
export const Product = "https://stagemain.crofarm.com/spa/";
export const GcpBase = "https://stagemain.crofarm.com/";

export const imgUrl = "https://img.crofarm.com/images/prodsmall/";
export const deliveryImgUrl = "https://img.crofarm.com/images/orders/delivery/";
